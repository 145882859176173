// Silverson styles based on Bootstrap 4.
// Author: Pavlo Tkachov (QLC)

@import 'variables';

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/flex";
@import "node_modules/bootstrap/scss/utilities/spacing";
@import "node_modules/bootstrap/scss/utilities/text";
@import "node_modules/bootstrap/scss/utilities/embed";
@import "node_modules/bootstrap/scss/utilities/float";

@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";
//@import "node_modules/swiper/swiper";

// Variables that can be set only after bootstrap is included.
$hero-margin-bottom-desktop: map-get($spacers, 5);

$grid-breakpoints: map-merge($grid-breakpoints, (xxl: 1500px));
$container-max-widths: map-merge($container-max-widths, (body: 1200px, xxl: 1500px));

// Top and bottom padding of the main content container (<main>).
$main-pt: map-get($spacers, 5);
$main-pb: map-get($spacers, 5) * 1.5;

@import 'fonts';
@import 'mixins';
@import 'navigation';
@import 'mega-sidebar';
@import 'forms';
@import 'carousel';
@import 'cards';
@import 'content-blocks';


.container-fluid {
  @each $breakpoint, $container-max-width in $container-max-widths {
    &.#{$breakpoint} {
      max-width: $container-max-width;
    }
  }
}


// --------------------------------------------------------------
// Headlines & displays
// --------------------------------------------------------------
//#{headings(1, 6, 'underlined')} {
//  border-bottom: map-get($spacers, 1) solid;
//}

/*h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  color: theme-color('primary');
}*/
h1, .h1 {
  line-height: 1;
  text-align: center;
  margin-bottom: map-get($spacers, 5);
}
h2 > a:hover { text-decoration: none; }
h4, .h4, h5, .h5 { font-weight: 300; }

@include media-breakpoint-up('md') {
  h1, .h1 { font-size: $h1-font-size-md; }
  h2, .h2 { font-size: $h2-font-size-md; }
  h3, .h3 { font-size: $h3-font-size-md; }
}
@include media-breakpoint-up('lg') {
  h1, .h1 { font-size: $h1-font-size-lg; }
  h2, .h2 { font-size: $h2-font-size-lg; }
  h3, .h3 { font-size: $h3-font-size-lg; }
  .display-4 { font-size: $display4-size-lg; }
}
@include media-breakpoint-down('md') {
   h2, .h2, h3, .h3 { font-weight: 300; }
}
// END of "Headlines & displays"


// Generic styles
// --------------------------------------------------------------
body {
  position: relative;
  color: theme-color('tertiary');
  padding-top: $header-height;

  // text-align: left is explicitly set in _reboot.scss:64.
  // It messes up with Arabic site, so I'm unsetting it here.
  text-align: inherit;

  &.overlay {
    width: 100%; // Fixes moving all content to the right with position: fixed.
    //position: fixed;
    overflow-y: scroll;
  }
}

p a {
  text-decoration: underline;
}

.lead { font-size: $font-size-base; }
@include media-breakpoint-up('sm') {
  .lead { font-size: $lead-font-size; }
}

main {
  margin-top: $main-pt;
  margin-bottom: $main-pb;

  // Compensating bottom margin of the <main> if a gray section (.section-gray) is the last child.
  .section-gray:last-child { margin-bottom: -$main-pb; }
  hr:last-child { display: none; }
}
body.homepage {
  main { margin-bottom: 0; }
}
dl { margin-bottom: 0 }

.news-link {
  display: inline-block;
  font-weight: 300;
  transition: color .1s;

  @include media-breakpoint-up(md) {
    font-size: 1.15rem;
  }
}
a.news-link { text-decoration: underline }

figcaption {
  text-align: center;
  font-style: italic;
  font-weight: 100;
  margin-top: 1rem;
}


#hero {
  margin-top: -$header-nav-height;
  margin-bottom: map-get($spacers, 3);

  &.internal-page {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 300px;
    padding-bottom: map-get($spacers, 3);
    background-size: cover;
    background-position: center;
  }

  @include media-breakpoint-up('sm') {
    margin-bottom: $hero-margin-bottom-desktop;
    &.internal-page { height: 350px; }
  }
}

.hero-headline-sbs {
  margin-top: -15rem;

  &.homepage-hero {
    > *:first-child {
      white-space: nowrap;
    }
    > *:last-child {
      color: white;
      font-size: $font-size-lg;
      line-height: 1.3;
    }
  }
}

// Resetting h1 margin-bottom because we're using 3rem by default for all h1.
h1.hero-headline, .h1.hero-headline { margin-bottom: $headings-margin-bottom; }

.hero-headline {
  color: white;
  text-align: center;
  text-shadow: 1px 1px 5px rgba(113, 111, 111, 0.3);

  @include media-breakpoint-down(xs) {
    font-weight: 300;
  }
}

// Fluid font-size.
// The video/reference: https://www.smashingmagazine.com/2016/05/fluid-typography
//
// On the screen sizes 0-960px I'm using viewport-relative units - vw - so the text scales automatically.
// On the big screens starting from 960px I'm using fixed width to not have giant headline.
//
// 960px is the breakpoint where 8.35vw reaches 5rem. Changing font-size to the fixed 6rem at this breakpoint eliminates jumping.
@media (max-width: 960px) {
  .hero-headline { font-size: 8.35vw; }
}
@media (max-width: 720px) {
  .hero-headline { font-size: 10vw; }
}
@media (min-width: 960px) {
  .hero-headline { font-size: $h1-font-size-lg; }
}


.text-accent {
  color: $text-accent-color;
}
b, strong {
  font-weight: bold;
  color: $text-accent-color;
}
* > p:last-child { margin-bottom: 0; }
dt { color: $text-accent-color; }
a:hover > img:first-child:last-child { opacity: .8; }
img {
  @extend .img-fluid;
}
.btn {
  //font-size: $font-size-lg;
  //font-weight: 300;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  cursor: pointer;

  &:focus, &.focus { box-shadow: none !important; }
}
.btn-tertiary, .btn-tertiary:hover {
  color: white;
}
.btn-outline-white:hover {
  color: theme-color('secondary');
  svg {
    fill: theme-color('secondary');
    stroke: theme-color('secondary');
  }
}
.table {
  thead th {
    border-top: none;
    border-bottom: 1px solid theme-color('tertiary');
  }
  thead th,
  tr td:first-child strong {
    color: $text-accent-color;
  }
}


.btn[data-toggle="collapse"] {
  padding-left: 1.3rem;

  &:before {
    content: '–';
    position: relative;
    top: .0675rem; // Slightly bigger margin to center minus with the center of the plus.
    font-size: 2rem;
    font-weight: 400;
    line-height: 0;
    margin-right: 0.2rem;
  }
  .collapse-toggle-more { display: none; }
  .collapse-toggle-less { display: inline; }

  &.collapsed {
    &:before {
      content: '+';
      top: .0625rem;
    }
    .collapse-toggle-more { display: inline; }
    .collapse-toggle-less { display: none; }
  }

  + .collapse {
    margin-top: map-get($spacers, 4);
  }
}


// @todo: .btn and a.callout have the same font.
.callout {
  font-size: $font-size-lg;
  font-weight: 300;
  line-height: $line-height-sm;
  border-bottom: 1px solid;

  &:hover {
    text-decoration: none;
  }
}
.callout-line {
  padding: .3rem 0;
  background-color: theme-color('primary');

  &, .callout {
    color: white;
  }
  .callout:hover {
    color: black;
  }
}
// END of Generic styles


.badge-tertiary { color: white; }
.description-headline {
  color: $text-accent-color;
}


// Side by side layout for two columns at "md" & "lg" breakpoints.
// --------------------------------------------------------------
@each $breakpoint, $max-width in $grid-breakpoints {
  @if $breakpoint == 'md' or $breakpoint == 'lg' {
    @include media-breakpoint-up($breakpoint) {
      .row.side-by-side-#{$breakpoint} {
        margin-right: 0;
        margin-left: 0;
        align-items: center;

        > [class~="col"], > [class*="col-"] {
          &:first-child {
            text-align: right;
            padding-right: map-get($spacers, 5);
          }
          &:last-child {
            text-align: left;
            padding-left: map-get($spacers, 5);
          }
        }
      }
    }
  }
}

.row[class*="side-by-side-"].border-separated > [class*="col-"] {
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);

  &:first-child {
    border-right: $sbs-border;
    @include border-radius(.1rem);
  }
}
// END



.section-spaced {
  margin-top: map-get($spacers, 5);
  margin-bottom: map-get($spacers, 5);

  > header {
    margin-bottom: map-get($spacers, 3);
  }
}
.section-gray {
  padding-top: map-get($spacers, 5);
  padding-bottom: map-get($spacers, 5);
  background-color: theme-color('light');

  border-top: 0.04rem solid $hr-border-color;
  &:not(:last-child) { border-bottom: 0.04rem solid $hr-border-color; }
}



// .spotlight is used on the homepage for the sections that highlights the products.
.spotlight {
  @include media-breakpoint-down(md) {
    &:last-child { margin-bottom: 0; }
  }

  .h1 { text-align: left }

  .img-product {
    max-height: 350px;
    width: auto;

    @include media-breakpoint-up(md) { max-height: 550px; }
    @include media-breakpoint-up(lg) { max-height: 100%; }
  }
  a:hover > .img-product { opacity: .9; }

  .callout-buttons {
    text-align: center;
    @include media-breakpoint-up(md) { text-align: left }

    .btn {
      margin-right: map-get($spacers, 3);
      &:last-of-type { margin-right: 0; }
    }
  }

  .videos-block {
    // Making a videos block full width by compensating .col paddings.
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;

    @include media-breakpoint-up(lg) {
      max-width: 28rem;
      margin-left: initial;
      margin-right: initial;
    }
  }

  @include media-breakpoint-down(sm) {
    .videos-block { text-align: center }
  }
}



// .product-section is used on the product pages for each of sections.
.product-section {
  .intro {
    text-align: center;
    margin-bottom: map-get($spacers, 5);
  }

  .read-more-wrapper {
    text-align: center;
    margin-bottom: -(map-get($spacers, 5));
  }
}


// Wistia specific: hides black volume button from the Video thumbnails (moving thumbnails).
//
// There is a way to hide it via embed option: plugin[videoThumbnail][clickToPlayButton]=false
// https://wistia.com/support/developers/embed-options#plugin-videothumbnail-clicktoplaybutton
// But in this case if a video doesn't have Video thumbnail enabled,
// it will show just a black rectangle instead of an image.
div[data-handle="click-for-sound-backdrop"] {
  display: none !important;
}


// --------------------------------------------------------------
// Mixers landing page images styles (/products)
// --------------------------------------------------------------
#mixers-landing-page {
  $max-height-1: 400px;
  $max-height-2: 174px;

  .row > *[class^="col-"] {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    margin-bottom: 4rem;
  }

  .card-img-top {
    width: auto;
    align-self: center; // align-self: center is used to prevent image stretching on IE and Edge.
  }
  .card-body {
    flex: none;
    height: 68px;
  }
  .card-title {
    font-family: $headings-font-family;
    line-height: 1.3;
  }

  .laboratory-mixers { max-height: 380px; }
  .pilot-scale-batch-mixers { max-height: 360px; }
  .batch-mixers { max-height: $max-height-1; }
  .ultramix-mixers { max-height: $max-height-1; }

  .in-line-mixers { max-height: $max-height-2; }
  .in-line-ultra-sanitary-mixer, .in-line-ultra-hygienic-mixer { max-height: $max-height-2; }
  .hv-in-line-mixers { max-height: $max-height-2; }
  .homogenizers, .homogenisers { max-height: $max-height-2; }
  .sanitary-mixers { max-height: $max-height-2; }

  .bottom-entry-mixers { max-height: 200px; }
}
// END


.social-links {
  text-align: center;
  margin-top: map-get($spacers, 4);

  a {
    color: $tertiary;
    transition: color .15s;

    &:not(:last-child) { margin: 0 2rem 0 0 }

    .svg-icon {
      height: 2rem;
      &.linkedin { height: 1.9rem }
      &.youtube {
        height: 2.3rem;
        margin-top: 0.2rem;
      }
    }
  }
  a:hover {
    color: $primary;
    text-decoration: none;
  }
}



.utility-block {
  display: none;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: theme-color('primary');
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.70);

  @include media-breakpoint-up('lg') {
    display: block;
  }

  .item {
    color: white;
    //text-align: center;
    text-align: left; // Temporary
    padding-top: 2rem;
    padding-bottom: 2rem;

    // Temporary
    &:not(:first-child) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &:first-child {
      text-align: left;
    }
    &:nth-child(3) {
      border-left: 1px solid rgba(255, 255, 255, .5);
      border-right: 1px solid rgba(255, 255, 255, .5);
    }

    &:nth-of-type(2) .item-text {
      margin-top: 0;
    }

    > a, > div {
      //display: inline-block;
      display: flex; // Temporary
      align-items: center; // Temporary
      justify-content: center; // Temporary

      color: white;
      &:hover {
        color: theme-color('secondary');
        text-decoration: none;
      }
    }
  }

  .svg-icon {
    fill: white;
    stroke: white;
    transform: rotate(-20deg);

    width: 100%; // Temporary

    &.phone { @include svg-icon-width('phone', 3.5rem); }
    //&.phone { @include svg-icon-width('phone', 4.25rem); } // Temporary
    &.envelope { @include svg-icon-width('envelope', 4.8rem); }
    //&.envelope { @include svg-icon-width('envelope', 7.4rem); } // Temporary
    //&.form { @include svg-icon-width('form', 4rem); }
    &.location { @include svg-icon-width('location', 4rem); }
    //&.location { @include svg-icon-width('location', 6rem); } // Temporary

  }
  a {
    @include hover-focus() {
      .svg-icon {
        fill: $secondary;
        stroke: $secondary;
      }
    }
  }

  .item-callout {
    margin-left: 1.5rem;

    display: inline-block;
    font-size: $font-size-base * 1.4;
    line-height: 1;
    font-weight: 300;
    text-align: left;
    vertical-align: top;

    &.xl {
      font-family: $headings-font-family;
      line-height: $line-height-sm;

      @media (min-width: 1120px) { font-size: $font-size-base * 1.8 }
    }

    > span {
      display: block;
      margin-left: $font-size-base;
    }
  }
}


#ma-grid {
  .card-title { font-size: 1.1rem; }
  .card-subtitle {
    font-size: .9rem;
    margin-top: 0;
  }

  /* Changing 33.3333333333% to 33% fixes the layout issue with Isotope
     when the cards don't fit 3 in a row (maybe because of a 1px difference or so). */
  @include media-breakpoint-up(lg) {
    .col-lg-4 {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}



// --------------------------------------------------------------
// Silverson Conversion Tools / Tank Volume Calculator
// --------------------------------------------------------------
#tankForm {
  .input-group-text:first-child {
    flex-grow: 1;
    justify-content: flex-end;
  }
  #tank-form-shape .input-group-prepend { width: 116px; }
  #tank-form-measurements .input-group-prepend { width: 217px; }
  .invalid-feedback { display: block; }
}
// END of Silverson Conversion Tools / Tank Volume Calculator



// --------------------------------------------------------------
// Search results page (AddSearch)
// --------------------------------------------------------------
#addsearch-results {
  font-size: inherit !important;

  a {
    font-size: inherit !important;
    color: theme-color('primary') !important;
  }

  // Excerpt of a page
  p { color: theme-color('tertiary') !important; }

  .addsearch-result-item-sub {
    margin: 1.5rem 0;
    padding: 1rem 0;
    border-bottom: none !important;
    min-height: auto !important;

    div { min-height: unset !important }
  }
  .addsearch-result-item-sub-active {
    background-color: theme-color('light') !important;
    h2 > a { color: $link-hover-color !important; }
  }

  // Title of a page
  .addsearch-result-item-sub h2 a {
    font-size: $font-size-lg !important;
    padding: map-get($spacers, 1) 0 !important;
  }

  // URL of a page
  .addsearch-result-item-sub p span.document_url {
    font-size: $font-size-sm !important;
    padding-bottom: 0 !important;
  }

  #addsearch-results-bottom { font-size: inherit !important; }
  #addsearch-rp-paging a:hover { background-color: theme-color('light'); }
  #addsearch-rp-paging { float: none !important; }
  #addsearch-logo { display: none !important; }


  @include media-breakpoint-down(sm) {
    .addsearch-result-item-container, .addsearch-result-item-sub {
      background-image: none !important;
    }

    .addsearch-result-item-sub { margin: 0 !important }
    .addsearch-result-item-sub div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
// END of "Search results page (AddSearch)"



.footer-info-block {
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);
  background-color: theme-color('secondary');

  h1, h2, h3, h4, h5, h6, .nav-link { color: theme-color('tertiary'); }
  //p { color: lighten($secondary, 25%); }

  .row[class*="side-by-side-"] {
    > [class*="col-"] {
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);

      &:first-child { text-align: center; }
    }

    @include media-breakpoint-down('md') {
      h3 { font-size: $h5-font-size + .1rem; }
      > [class*="col-"] {
        &:first-child {
          border-right: none;
          border-bottom: .1rem solid rgba(255, 255, 255, .25);
        }
      }
    }

    @include media-breakpoint-up('lg') {
      h3 { font-size: $h3-font-size; }
      > [class*="col-"]:first-child { text-align: right; }
    }
  }

  &.with-2-videos {
      @include media-breakpoint-up('lg') {
        .row[class*="side-by-side-"] > [class*="col-"]:first-child { flex-basis: 30% }
      }
  }
}

#footer-menu {
  .nav-link { font-size: clamp(1rem, 4vw, 1.15rem) }

  @include media-breakpoint-up('lg') {
    > [class*="col"]:first-child .nav-link { padding-left: 0 }
    > [class*="col"]:last-child .nav-link { padding-right: 0 }
  }

  a:hover { color: inherit }
}


#footer {
  font-size: $font-size-base * .90; // -10%
  background-color: $footer-bg-color;

  @extend .py-4;

  &, a { color: #5d5d5d }
  .svg-logo {
    @include svg-icon-width('logo', 11rem);
    fill: theme-color('tertiary');
  }

  @include media-breakpoint-down('md') {
    text-align: center;
  }
}

#footer-inner {
  display: flex;
  justify-content: center;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;

  @include media-breakpoint-up('lg') {
    justify-content: space-between;
  }
}

#copyright {
  color: $footer-text-color;
  margin-top: map-get($spacers, 3);
}



.clocks {
  display: flex;
  text-align: center;

  > div:not(:first-child) { margin-left: clamp(.9rem, 1.6vw, 1.625rem) }
  > div:not(:last-child) { margin-right: clamp(.9rem, 1.6vw, 1.625rem) }
}

.clock {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;

  %clock-hand {
    position: absolute;
    width: .125rem;
    left: 50%;
    border-radius: 0.0625rem;
    background-color: $footer-text-color;
    transform-origin: bottom;
  }

  .hour {
    @extend %clock-hand;
    $height: 28%;
    $top: 50% - $height;

    height: $height;
    top: $top;
  }
  .minute {
    @extend %clock-hand;
    $height: 36%;
    $top: 50% - $height;

    height: $height;
    top: $top;
  }

  & + .tz-city {
    display: inline-block;
    @extend .mt-1;
  }
}



#sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding-top: $sf-padding-y;
  padding-bottom: $sf-padding-y;
  border-top: $sn-border-bottom-width solid #dfdfdf;
  background-color: theme-color('light');
  margin-top: $main-pb;

  .row {
    justify-content: center;
    align-items: center;
  }
  .product-name {
    @extend .h4;
    display: none;
    color: $text-accent-color;
    margin-bottom: 0;
    padding-right: 3rem;

    // Some of the product names are very long and we need to handle such cases as we can.
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // Because of "overflow: hidden" some bottom parts of letters are cropped ("g" for example).
    // To fix this I've increased line-heigh from 1.2 (default for headings).
    line-height: 1.3;

    @include media-breakpoint-up(lg) { display: block }
  }

  @include media-breakpoint-down(xs) {
    padding-top: 0;
    padding-bottom: 0;

    .buttons {
      display: flex;
      width: 100%;
    }
    .btn {
      font-size: 1.1rem;
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
body.with-sticky-footer main { margin-bottom: 0 }



#floating-form-button {
  position: fixed;
  left: .375rem;
  bottom: .375rem;
  width: 3.75rem;
  height: 3.75rem;
  padding: .375rem;
  border-radius: 0.5rem;
  opacity: .8;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }
}


/* Workhead images on the Product page.
   Some of them are too big and we need to restrict the height. */
.workhead-img { max-height: 200px }


$border-top-width: .4rem;
$popup-margin-x: 10%;

.popup {
  display: none;
  position: fixed;
  top: $header-height * 3;
  right: 0;
  left: 0;
  z-index: 1;
  //width: 80% - $popup-margin-x * 2;
  //max-width: $modal-mobile;
  padding: 1rem 1rem 2rem;
  margin: 0 auto;
  border-top: $border-top-width solid theme-color('primary');
  background-color: $popup-bg;

  @include media-breakpoint-up(xl) { max-width: $modal-xl; }

  .close {
    position: absolute;
    top: 0;
    right: 1rem;
    font-size: 2.5rem;
    color: theme-color('primary');
    opacity: 1;

    @include hover-focus() {
      color: lighten(theme-color('primary'), 5%);
      cursor: pointer;
    }
  }
}

#popup-search {
  $padding-y: 7rem;
  padding: $padding-y - $border-top-width 6rem $padding-y;
  .form-control, .input-group .btn { border: 1px solid gray('200'); }

}


/** The language indicator and the language selector popup are styled with fixed font to look
    the same regardless of language.
 */
#popup-language-selector {
  max-width: $modal-lg;

  .nav-list {
    @include list-unstyled();
    columns: 3;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0;
  }
  li {
    font-size: 1rem; // Original $font-size-base
    line-height: 1.5; // Original $line-height-base
  }
  a {
    font-family: $font-encode-sans-expanded;
    font-size: 1.15rem; // Original $nav-font-size
  }
}

#header-navigation #toggle-language,
#header-navigation #toggle-search{
  font-family: $font-encode-sans-expanded;
  font-size: 1.15rem; // Original $nav-font-size
  line-height: 3rem;
}


#body-overlay {
  display: none;
  position: absolute;
  top: $header-height;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.8;
  background-color: $body-overlay;
}



// --------------------------------------------------------------
// Bootstrap modals
// --------------------------------------------------------------
.modal-content {
  border-top: $border-top-width solid theme-color('primary');
}
.modal-title {
  width: 100%;
  text-align: center;
}
.modal-body:last-child { padding-bottom: $modal-header-padding + $modal-inner-padding; }
.modal-backdrop {
  background-color: $body-overlay;
  &.show { opacity: .8; }
}

// Vertically aligns a .close button with a .modal-title.
.modal-header .close { padding-top: .6rem; }

.close {
  font-family: Arial, serif; // To avoid overwriting the font by other microsites.
  opacity: .75;

  @include hover-focus() {
    opacity: 1;
  }
}

// Fixing jumping on modal open. It's the known caveat with fixed elements and the Bootstrap modals.
// http://getbootstrap.com/docs/4.0/components/modal/#how-it-works
.modal-open {
  //#toggle-mega-sidebar, #utility-navigation, #secondary-navigation, #sticky-footer.sticky { padding-right: 17px; }
}
// END of "Bootstrap modals"


// Hacks for Internet Explorer and Edge.
// Removes visible vertical scroll bar when the sidebar is closed.
// As a side effect: sliding animation is lost for these two browsers.
// IE:
_:-ms-fullscreen, :root #mega-sidebar {
  display: none;
  &.show { display: block; }
}
// Edge:
@supports (-ms-ime-align: auto) {
  #mega-sidebar {
    display: none;
    &.show { display: block; }
  }
}

// A hacks for Internet Explorer >= 11 to fix the inputs height.
_:-ms-fullscreen, :root .form-control {
  padding-top: .59rem;
  padding-bottom: .59rem;
}


@include media-breakpoint-up(md) {
  _:-ms-fullscreen, :root .modal-content {
    margin-top: 8rem;
  }
}


// --------------------------------------------------------------
// Legacy.
// Styles to support HTML markup from the old website that are stored in a database and not available for easy change.
// --------------------------------------------------------------

/* "span.required" is used on the pages with forms. */
span.required {
  color: $required-color;
}

// END of "Legacy"



.notification-strip {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  font-size: clamp(1rem, 4vw, 1.2rem);
  color: white !important;
  padding: 0.5rem 0;
  z-index: $nav-z-index;

  &:hover { text-decoration: none }
}

#covid-strip {
  background-color: theme-color('primary');
  &:hover { background-color: darken(theme-color('primary'), 4%) }
}

/*#isaias-strip {
  background-color: #d2002f;
  &:hover { background-color: darken(#d2002f, 4%) }
}*/

.product-mobile-img {
  @include media-breakpoint-down(xs) {
    max-height: 325px;
  }
}



//
// BROCHURE
//
.flipbook-container iframe {
  max-height: calc(100vh - #{$header-nav-height + $header-nav-top-border-width} - #{$main-pt});
  aspect-ratio: 2800/1960;
  width: 100%;
  border: 0;

  @include media-breakpoint-down(sm) {
    aspect-ratio: 375/385;
  }
}

.brochure-image {
  max-width: 125px;
  border: 1px solid $light;
}


//
// OVERLAY
//
.overlay-wrapper {
  display: flex;
  align-items: center;

  .overlay {
    position: absolute;
    max-width: 600px;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    margin-inline: auto;
  }
}


.see-video-link {
  color: white;
  font-size: $font-size-lg;
  transition: color .15s;

  &:hover { color: $primary }

  svg {
    width: 1.5em;
    vertical-align: top;
    margin-left: .25rem;
  }
}

a.see-video-link:hover { text-decoration: none }


#product-header-video {
  aspect-ratio: 424/240;

  @include media-breakpoint-up(sm) {
    aspect-ratio: 4/1;
  }

  .overlay {
    text-shadow: 1px 1px 5px rgb(113 111 111 / 30%);
  }
}


// --------------------------------------------------------------
// Look North Redesign
// --------------------------------------------------------------

form .info-box {
    background-color: #E6F7FF;
    border-left: 4px solid #00AEEF;
    padding: 15px;
    margin-bottom: 0px;
}

form .info-box p {
    margin: 0;
    color: #0077A1;
}

.modal-dialog {
  max-width:530px;
}



