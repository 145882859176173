// Bootstrap Carousel
.carousel {
  $arrow-size: 3.5rem;
  $arrow-color: $tertiary;
  $arrow-hover-color: #a5a5a5;

  padding-left: map-get($spacers, 5);
  padding-right: map-get($spacers, 5);

  // Bootstrap 4 carousel.
  .carousel-control-prev, .carousel-control-next {
    top: 1rem;
  }
  .carousel-control-prev {
    @include arrow(left, $arrow-size, $arrow-color, $arrow-hover-color);
    border-right-width: $arrow-size / 2.5;
  }
  .carousel-control-next {
    @include arrow(right, $arrow-size, $arrow-color, $arrow-hover-color);
    border-left-width: $arrow-size / 2.5;
  }

  // Slick carousel (http://kenwheeler.github.io/slick)
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-prev, .slick-next {
    opacity: .6;
    &:hover { opacity: 1; }
    &:before { display: none; }
  }
  .slick-prev {
    @include arrow(left, $arrow-size, $arrow-color, $arrow-hover-color);
    left: 0;
    border-right-width: $arrow-size / 2.5;
  }
  .slick-next {
    @include arrow(right, $arrow-size, $arrow-color, $arrow-hover-color);
    right: 0;
    border-left-width: $arrow-size / 2.5;
  }
}


// Slick Carousel
#customers-carousel {
  .slick-slide > img {
    max-width: 60%;
    margin: 0 auto;
  }

  @include media-breakpoint-up('lg') {
    .slick-slide > img { max-width: 75% }
  }
}


// Swiper Carousel.
// Currently, used only for the product Mixer Options.
.swiper { width: 100% }

.swiper-button-prev, .swiper-rtl .swiper-button-next { left: 0 }
.swiper-button-next, .swiper-rtl .swiper-button-prev { right: 0 }
.swiper-button-prev, .swiper-button-next {
  --padding-x: .75rem;
  --padding-y: 1rem;

  width: calc(var(--swiper-navigation-size) / 44 * 27 + var(--padding-x) * 2);
  height: calc(var(--swiper-navigation-size) + var(--padding-y) * 2);

  &:not(.swiper-button-disabled) {
    --swiper-theme-color: lighten($tertiary, 20%);

    padding: var(--padding-x) var(--padding-y);
    background-color: $secondary;
    transition: background-color .2s, color .2s;
  }
}


#js-mixer-options-carousel {
  --container-mx: calc(#{$grid-gutter-width} / 2);
  --nav-button-padding-x: .75rem;
  --slide-width: 368px;
  --nav-buttons-mt: calc(var(--slide-width) / 1.7);
  --card-label-height: 2.15rem;

  padding-top: var(--card-label-height);
  padding-left: 1rem;

  @include media-breakpoint-up(md) {
    padding-inline: calc(var(--swiper-navigation-size) / 44 * 27 + var(--nav-button-padding-x) * 2 + var(--container-mx));

    .swiper-slide { width: var(--slide-width) }
    .swiper-button-prev, .swiper-button-next {
      top: calc(var(--nav-buttons-mt) + var(--swiper-navigation-size) + var(--padding-y));
    }
  }

  @include media-breakpoint-down(xs) {
    .swiper-button-prev, .swiper-button-next { display: none }
    .swiper-slide { width: 80% }
  }
}
