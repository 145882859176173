// --------------------------------------------------------------
// Custom cards (Bootstrap 4 .card component).
// --------------------------------------------------------------
.card-title, .card-subtitle {
  font-family: $font-family-base;
}

.card-img, .card-img-top { transition: $transition-fade }
a.card:hover {
  text-decoration: none;
  .card-img, .card-img-top { opacity: .9; }
}

.card-simple {
  border: none;
  text-align: center;
  background-color: transparent;

  .card-body { padding: 0; }
  .card-title { margin-top: map-get($spacers, 2); }
}
a.card-simple:hover .card-title { color: $link-hover-color; }

// .card-category is used on the Mixing Application grid.
.card-category {
  border: none;
  background-color: transparent;

  .card-body { padding: map-get($spacers, 2) map-get($spacers, 1); }
  // The default $card-spacer-y is .75rem. I decided not to change the variable itself because it's used in few places.
  .card-title { margin-bottom: $headings-margin-bottom; }
}


.card-centered {
  text-align: center;

  .card {
    display: inline-block;

    .card-img-top {
      //display: block;
      width: auto;
    }
    //.card-body { display: inline-block; }
  }
}
// END of "Custom cards"


// "Newest Model" and similar labels on cards.
.card-label-wrapper {
  position: absolute;
  width: 100%;
  height: var(--card-label-height);
  top: calc(var(--card-label-height) / 2 * -1);
}
.card-label {
  display: flex;
  height: 100%;
  width: fit-content;
  font-size: 1.15rem;
  color: #fff;
  padding-inline: 1.25rem;
  background-color: $primary;
  margin-inline: auto;
  align-items: center;
}


//
// Expandable card.
// Used in the Mixer Options.
//
.card-v2 {
  --transition-duration: .4s;

  .card-img-top {
    aspect-ratio: 16/9;
    object-fit: contain;
    background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 50%, rgba(240,240,240,1) 100%);
  }
  .card-title { color: $secondary }
  .card-text {
    position: relative;
    max-height: 200px;
    overflow-y: hidden;
    transition: max-height var(--transition-duration);

    &.expanded { z-index: 1 }
  }
}

// Read More / Read Less button
.card-v2 .read-more {
  display: none;
  position: relative;
  width: 100%;
  height: 120px;
  color: $primary;
  justify-content: center;
  align-items: end;
  background: linear-gradient(to bottom, transparent 0%, white 60%);
  transition: all var(--transition-duration);
  border: none;
  margin-block: -70px -#{$card-spacer-x};

  &.show { display: flex }
  &:hover { color: darken($primary, 10%) }

  .read-more-label, .read-less-label {
    position: absolute;
    transition: opacity var(--transition-duration);
  }
  .read-more-label { opacity: 1 }
  .read-less-label { opacity: 0 }

  .read-more-label:after, .read-less-label:after {
    position: relative;
    font-size: 2rem;
    font-weight: 400;
    line-height: 0;
    margin-left: .5rem;
  }
  .read-more-label:after {
    content: '+';
    top: .1rem;
  }
  .read-less-label:after {
    content: '–';
    top: .15rem; // Slightly bigger margin to center minus with the center of the plus.
  }

  &.expanded {
    .read-more-label { opacity: 0 }
    .read-less-label { opacity: 1 }
  }
}


//
// Rectangular card with overlay image and a line of text sliding out on hover.
// Used on the Case Studies landing page.
//
.card-overlay {
  aspect-ratio: 1/1;

  .card-img {
    height: 100%;
    object-fit: cover;
    filter: brightness(.7);
  }

  .card-img-overlay {
    backdrop-filter: blur(2px);
  }

  .card-title {
    font-family: $font-encode-sans-expanded;
    text-shadow: 0 0 31px black;
  }

  .card-hover-text {
    --height: 40px;
    position: absolute;
    width: 100%;
    height: .25rem;
    left: 0;
    bottom: 0;
    line-height: var(--height);
    transition: $transition-collapse;
    font-size: $lead-font-size;
    background-color: $primary;
  }

  &:hover .card-hover-text { height: var(--height) }
}
