//
// I'm setting up some of the Bootstrap 4 default variables to be able to use them here until Bootstrap is included.
//
$font-size-base: 1rem !default; // bootstrap/scss/_variables.scss:234
$line-height-base: 1.5 !default; // bootstrap/scss/_variables.scss:243
$h1-font-size: $font-size-base * 2.5 !default; // bootstrap/scss/_variables.scss:245
$font-size-lg: $font-size-base * 1.25; // bootstrap/scss/_variables.scss:235
$input-btn-line-height: $line-height-base; // bootstrap/scss/_variables.scss:327
$input-btn-padding-y: .375rem; // bootstrap/scss/_variables.scss:325
$nav-link-padding-y: .5rem; // bootstrap/scss/_variables.scss:572
// -----------------------------------------------------------------------------


$body-color: black;
$primary: #00b0ed;
$secondary: #303030;

// Tertiary color is used in text.
$tertiary: darken(#aeaeae, 20%);
$text-accent-color: darken(#aeaeae, 35%);
//$light: #ced4da; // $gray-400;
//$light: #e9ecef; // $gray-200;

// $light color also is used for separators (<hr> and borders).
$light: #f5f5f5; // $gray-200;

$navbar-light-color: #6c6c6c;

$footer-text-color: #5d5d5d;
$footer-bg-color: darken($secondary, 10%);

$popup-bg: rgba(255, 255, 255, .85);
$body-overlay: #484848;

$carousel-control-opacity: 1;


:root {
  --swiper-theme-color: $tertiary;
  --swiper-navigation-size: 34px;
}


// --------------------------------------------------------------
// Header & Navigation
// --------------------------------------------------------------
$header-nav-top-border-width: .15rem;
$header-nav-height: 4.2rem;
$header-height: $header-nav-height + $header-nav-top-border-width;
$utility-nav-link-margin-x: .25rem;

$nav-z-index: 1060; // $zindex-popover
$mega-menu-padding-y: 2.5rem;
$nav-font-size: $font-size-base * 1.15 !default;

$toggle-arrow-space: 1rem;

// Secondary navigation
// --------------------------------------------------------------
$sn-padding-x: .2rem;
$sn-border-bottom-width: .0625rem;
$sn-height: $nav-font-size * $line-height-base + $nav-link-padding-y * 2 + $sn-padding-x * 2 + $sn-border-bottom-width;
// END of "Navigation"



// Modals
// --------------------------------------------------------------
$modal-mobile: 90%;
$modal-xl: 1050px;
$modal-content-border-width: 0;
$modal-content-bg: $popup-bg;
$close-font-size: 2.5rem;
$close-color: $primary;
$modal-dialog-margin-y-sm-up: 1rem;
// END of "Modals"


// Sticky footer
// --------------------------------------------------------------
$sf-padding-y: .5rem; // map-get($spacers, 2)


$font-encode-sans-expanded: 'Encode Sans Expanded', sans-serif;

$font-family-base: 'Roboto', sans-serif !default;
$headings-font-family: $font-encode-sans-expanded !default;
$headings-font-weight: 200; // Extra light
$headings-color: $primary;


$h1-font-size-lg: 5rem !default;
$h1-font-size-md: $h1-font-size-lg  - $h1-font-size-lg * .35; // 5rem - 35% = 3.25rem

$h2-font-size-lg: $h1-font-size-md;
$h2-font-size-md: $h2-font-size-lg  - $h2-font-size-lg * .35; // 3.25rem - 35% = 2.11rem

$h3-font-size-lg: $h2-font-size-md;
$h3-font-size-md: $h3-font-size-lg  - $h3-font-size-lg * .35; // 2.11rem - 35% = 1.37rem


$display4-size: $h1-font-size;
$display4-size-lg: $display4-size * 1.5;

$display1-weight: 200;
$display2-weight: $display1-weight;
$display3-weight: $display1-weight;
$display4-weight: $display1-weight;


$line-height-sm: 1.25;
$hr-margin-y: 3rem; // map-get($spacers, 5)
$border-radius-lg: .2rem; // .2rem is the $border-radius-sm by default.
$sbs-border: .1rem solid rgba(255, 255, 255, .25); // Side by side blocks separating border


// Forms
// --------------------------------------------------------------
$input-height-inner: ($font-size-lg * $input-btn-line-height) + ($input-btn-padding-y * 2);
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$input-border-color: rgba(black, .125); // The same as $card-border-color.
$input-group-addon-bg: theme-color('light');
$required-color: darkred;
$input-placeholder-color: $tertiary;
// END of "Forms"




$theme-colors: (
  'text-hover': #757575,
  'tertiary': $tertiary,
  'light': $light,
  'white': white
);

$badge-padding-y: .4em; // The same as $badge-padding-x;


// --------------------------------------------------------------
// HAMBURGER
// See defaults at node_modules/hamburgers/_sass/hamburgers/hamburgers.scss:12
// --------------------------------------------------------------
$hamburger-types: (slider);
$hamburger-layer-color: $primary;
$hamburger-padding-x: 0;
$hamburger-padding-y: $hamburger-padding-x;
$hamburger-layer-width: 2.5rem; // 40px
$hamburger-layer-height: .25rem; // 4px
$hamburger-layer-spacing: .375rem; // 6px
$hamburger-layer-border-radius: .125rem; // 2px
$hamburger-hover-opacity: .8;

$header-hamburger-right: 1rem; // $navbar-padding-x
// END of Hamburger


// --------------------------------------------------------------
// SVG ICONS
// --------------------------------------------------------------
// The sizes are taken from the viewBox attribute in the SVG files.
$svg-icons-sizes: (
  logo: (
    width: 451,
    height: 103
  ),
  search: (
    width: 398.61,
    height: 330.82
  ),
  phone: (
    width: 330,
    height: 582
  ),
  envelope: (
    width: 475,
    height: 474.19
  ),
  //form: (
  //  width: 733.11,
  //  height: 871
  //),
  location: (
    width: 739,
    height: 1080
  ),
);

@mixin svg-icon-width($type, $width) {
  $percent: $width * 100% / map-get(map-get($svg-icons-sizes, $type), 'width');
  max-width: $width;
  max-height: map-get(map-get($svg-icons-sizes, $type), 'height') * $percent / 100%;
}
@mixin svg-icon-height($type, $height) {
  $percent: $height * 100% / map-get(map-get($svg-icons-sizes, $type), 'height');
  max-height: $height;
  max-width: map-get(map-get($svg-icons-sizes, $type), 'width') * $percent / 100%;
}
// END of SVG ICONS
