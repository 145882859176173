// --------------------------------------------------------
// arrows
// --------------------------------------------------------
// $direction: top, left, right, bottom, top-left, top-right, bottom-left, bottom-right
// $color: hex, rgb or rbga
// $size: px or em
// @example
// .element{
//     @include arrow(top, #000, 50px);
// }
@mixin arrow($direction, $size, $color, $hover-color: null) {
  display: inline-block;
  height: 0;
  width: 0;


  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size * 1.5 solid $color;

    @if $hover-color {
      &:hover { border-bottom-color: $hover-color; }
    }
  }
  @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size * 1.5 solid $color;

    @if $hover-color {
      &:hover { border-left-color: $hover-color; }
    }
  }
  @else if $direction == 'bottom' {
    border-top: $size * 1.5 solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;

    @if $hover-color {
      &:hover { border-top-color: $hover-color; }
    }
  }
  @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size * 1.5 solid $color;
    border-bottom: $size solid transparent;

    @if $hover-color {
      &:hover { border-right-color: $hover-color; }
    }
  }
}


// The "headings" function targets all "h" tags and generates optimized output (h1, h2, ...).
// Usage: #{headings(1, 6, 'classname')} { ... }
@function headings($from: 1, $to: 6, $class: null) {
  $result: '';

  @if $class { $class: '.' + $class }

  @for $i from $from through $to {
    $result: 'h#{$i}#{$class}, ' + $result;
  }

  @return $result;
}
