/**
The ".content" parent class and individual ".content-XXX" classes style elements in the user-generated content,
mainly using the Fluid field type. The first place these classes were used at is the Case Studies section.
 */

.content {
  // "overflow: hidden" contains any possibly floated images inside the content area.
  overflow: hidden;

  h2, .h2, h3, .h3 {
    @extend .mt-5, .mb-3;
  }

  p, li { font-size: 1.1rem }
  li { @extend .mb-2 }

}
.case-study h1{
  margin-bottom:3rem;
}
.side-img {
  padding-left:2rem;
}

@media only screen and (max-width: 767px) {
  .side-img {
    padding-left:0rem;
  }
}

.content-images-grid {
  display: grid;
  grid: auto / repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;

  figure { margin-bottom: 0 }
}

.content-image {
  &.float-right, &.float-left {
    max-width: 50%;
  }
}

.content-callout {
  gap: 2rem;
}

@include media-breakpoint-up(md) {
  .width-75 { max-width: 75% }
  .width-50 { max-width: 50% }
  .width-30 { max-width: 30% }
}
