%nav-link-style {
  font-family: $headings-font-family;
  font-size: $nav-font-size;
  font-weight: $font-weight-light;
  color: $navbar-light-color;

  &.active, &:hover {
    color: black;
    text-decoration: underline;
    background-color: transparent;
  }
}

.nav-list {
  > li { margin-bottom: .45rem * 2 }
  a { @extend %nav-link-style }
}

.navbar-brand { margin-right: 0 }

#header-navigation {
  position: fixed;
  top: 0;
  z-index: $nav-z-index;
  width: 100%;
  height: $header-nav-height + $header-nav-top-border-width;
  padding-top: 0;
  padding-bottom: 0;
  border-top: $header-nav-top-border-width solid theme-color('primary');
  background-color: rgba(245, 245, 245, 1);

  // There is fixed width as the fix for Firefox without wich the navigation bar is jumping when the dropdown is opened.
  .svg-logo {
    fill: #00b0ed;
    @include svg-icon-width('logo', 10rem);
    width: 10rem; // Fix for Firefox as of Jan 2, 2018

    @include media-breakpoint-up('sm') {
      @include svg-icon-width('logo', 13rem);
      width: 13rem; // Fix for Firefox as of Jan 2, 2018
    }
  }
  .navbar-nav {
    height: 100%;

    &.center-menu {
      display: none;
      width: 100%;
      justify-content: center;

      .nav-item {
        margin-left: 2.6%;
        margin-right: 2.6%;
      }

      // Don't wrap the header menu items when the browser window gets smaller.
      .nav-link { white-space: nowrap }

      @include media-breakpoint-up('lg') { display: flex; }
    }
  }

  // Removes an arrow
  .dropdown-toggle:after { display: none; }

  .nav-link, .dropdown-item {
    @extend %nav-link-style;
  }
  .dropdown-item {
    padding-top: .45rem;
    padding-bottom: .45rem;
  }
  .nav-link {
    line-height: $header-nav-height - $nav-font-size;
    border-bottom: 0.35rem solid transparent;

    &:hover, &.active {
      text-decoration: none;
      border-bottom-color: theme-color('primary');
    }
  }
}


#utility-navigation {
  height: 100%;
  margin-left: auto;
  // margin-right explained:
  //    $nav-link-margin-x * 2 — margin between two side-by-side elements in the menu
  //    .5rem — right padding of the last .nav-link element (see _navbar.scss:166)
  margin-right: $header-hamburger-right + $hamburger-layer-width + $utility-nav-link-margin-x * 2 + .5rem - $navbar-padding-x;

  #un-mobile {
    .svg-icon {
      $icon-height: 2.2rem;

      fill: theme-color('primary');
      stroke: theme-color('primary');

      &.phone { @include svg-icon-height('phone', $icon-height); }
      &.envelope { @include svg-icon-height('envelope', $icon-height); }
    }
  }
  #un-desktop {
    display: none;

    .svg-icon.search {
      @include svg-icon-width('search', 2rem);
      // Fixed width is the fix for Firefox (on Jan 2, 2018).
      // Without it the width of the icon is collapsed by some reason and looks not aligned.
      width: 2rem;
      fill: theme-color('tertiary');
    }
  }

  .nav-link {
    &:not(:first-child) { margin: 0 $utility-nav-link-margin-x; }
    &:not(:last-child) { margin: 0 $utility-nav-link-margin-x; }
  }
  a {
    @include hover-focus() {
      .svg-icon.search { fill: theme-color('primary'); }
      .badge-tertiary { background-color: theme-color('primary'); }
    }
  }

  @include media-breakpoint-up('md') {
    #un-mobile { display: none; }
    #un-desktop { display: flex; }
  }
}

#toggle-mega-sidebar.hamburger {
  $hamburger-height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;

  position: fixed;
  top: $header-height - ($header-height / 2) - ($hamburger-height / 2) + $header-nav-top-border-width;
  right: $header-hamburger-right;
  z-index: 3;
  line-height: 0; // Removes extra space from the bottom.

/*  &.is-active {
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: white;
    }
  }*/
}

// Assumes that the target is a section with 3rem top-margin, an <hr> is before it and there is no secondary navigation.
:target:before {
  content: '';
  display: block;
  height: $header-height + map-get($spacers, 5) + .0625rem;
  margin-top: -($header-height + map-get($spacers, 5) + .0625rem);
}


@media (max-width: 340px) {
  $padding-x: .5rem;

  #header-navigation.navbar {
    padding-left: $padding-x;
    padding-right: $padding-x;
  }
  #utility-navigation {
    .nav-link:not(:last-child) { margin-right: 0; }
    .nav-link:not(:first-child) { margin-left: 0; }
  }
  #toggle-mega-sidebar.hamburger { right: $padding-x; }
}



// --------------------------------------------------------------
// SECONDARY NAVIGATION
// The second row of sticky navigation used with Bootstrap 4 Scrollspy.
//
// The ":target:before" is used to compensate the height of top and secondary navigation
// to make browser to scroll a page to the right position of an anchor.
// https://css-tricks.com/hash-tag-links-padding/
// --------------------------------------------------------------
#secondary-navigation {
  display: none;
  position: fixed;
  width: 100%;
  top: $header-height;
  z-index: $nav-z-index - 1;
  padding-top: $sn-padding-x;
  padding-bottom: $sn-padding-x;
  border-bottom: $sn-border-bottom-width solid #dfdfdf;
  background-color: rgba(255, 255, 255, .9);

  &.stay-on-mobile {
    display: flex;
    position: initial;

    @include media-breakpoint-up(md) { position: fixed; }
  }

  .nav-link {
    @extend %nav-link-style;
    color: theme-color('primary');
  }
}
body.with-secondary-navigation {
  @include media-breakpoint-up('md') {
    padding-top: $header-height + $sn-height;

    #hero {
      margin-top: -($header-height + $sn-height);
    }

    #secondary-navigation { display: flex; }
    :target:before {
      height: $header-height + $sn-height + map-get($spacers, 3);
      margin-top: -($header-height + $sn-height + map-get($spacers, 3));
    }
    .product-section :target:before {
      // I'm adding <hr> values to the calculation because all product's sections are separated by <hr>.
      // .0625rem is 1px of the <hr> border-width.
      height: $header-height + $sn-height + $hr-margin-y - .0625rem;
      margin-top: -($header-height + $sn-height + $hr-margin-y - .0625rem);
    }
  }
}
// END of Secondary navigation



.mega-menu {
  width: 100%;
  border: none;
  border-top: .0625rem solid #cccccc;

  // Resetting unnecessary Bootstrap styles.
  padding: 0;
  margin-top: 0;
  border-radius: 0;

  > .row {
    padding-top: $mega-menu-padding-y;
    padding-bottom: $mega-menu-padding-y;

    [class^="col-"] {
      &:first-child { padding-right: map-get($spacers, 5); }
      &:last-child { padding-left: map-get($spacers, 5); }
    }
  }

  .callout-line {
    font-size: $font-size-lg;
    font-weight: 300;
    //line-height: $line-height-sm;
    //height: 2.5rem;
    text-align: center;
  }

  &.dropdown-menu {
    // Overriding the default z-index (1000, $zindex-dropdown) to let users to navigate to another page when a modal is opened.
    z-index: $nav-z-index;
  }
}


%tab {
  font-size: $font-size-base * 1.4;
  font-weight: 300;
  color: theme-color('tertiary');
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  border-radius: 0;

  &:hover, &.active {
    color: $text-accent-color;
    background-color: #f5f5f5;
  }
}

.tabs-container {
  .tab-content:first-child {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }

  .row[class*="side-by-side-"] {
    text-align: center;
  }

  .tabs {
    text-align: center;

    .nav {
      display: inline-flex;
      border: 0.04rem solid $hr-border-color;

      > .nav-link {
        @extend %tab;
        flex-grow: 1;
        padding-left: 4rem;
        padding-right: 4rem;
        border: 0.04rem solid lightgray;
      }
    }
  }
}


// --------------------------------------------------------------
// Accordions
// --------------------------------------------------------------
.accordion {
  .accordion-item {
    margin-bottom: map-get($spacers, 2);

    &:last-child {
      margin-bottom: 0;
      .pane-content { padding-bottom: 0; }
    }

    [data-toggle="collapse"] {
      @extend %tab;
      line-height: 1.25;
      padding-top: .7rem;
      padding-bottom: .7rem;
      // rgba($black, .07) is an <hr> border color, but lightened a little bit. Originally it's rgba($black, .1).
      border: 1px solid rgba($black, .07);
      color: $text-accent-color;
      background-color: lighten(#f5f5f5, 2.8%);

      &:hover, &:not(.collapsed) {
        background-color: #f5f5f5;
      }
    }

    // I'm using nested element (.collapse > .pane-content) to set padding for content
    // because if it will be set to the .collapse directly there will be jumping in slide-up/down animation.
    .pane-content {
      padding: map-get($spacers, 3) map-get($spacers, 3);

      h2 { @extend .h5; }
      p:last-child { margin-bottom: 0; }
    }
  }

  &.with-arrows .accordion-item [data-toggle="collapse"] {
    display: flex;
    align-items: baseline;
    padding-left: 0;

    &:before {
      @include arrow(bottom, .5rem, $hr-border-color);
      position: relative;
      top: calc(-.0625rem * 3); // 3px
      margin-left: .7rem;
      margin-right: .7rem;
      content: '';
      transition: transform 0.15s ease;
    }
    &.collapsed:before {
      transform: rotate(-90deg);
    }
  }
}
ul.accordion {
  padding-left: 0;
  list-style: none;
}

// Two-level tree accordion (using ul > li > a + ul > li ... structure).
// --------------------------------------------------------------
.accordion-tree {
  .accordion-item {
    margin-bottom: map-get($spacers, 1);
    &:last-child {
      margin-bottom: 0;
    }

    [data-toggle="collapse"] {
      font-size: $font-size-lg;

      &:before {
        display: inline-block;
        width: 1rem;
      }

      &.collapsed {
        &:before {
          content: '+';
          position: relative;
          top: -2px;
        }
      }
      &:not(.collapsed) {
        &:before {
          content: '−'; // It's not a dash, but the minus (https://en.wikipedia.org/wiki/Plus_and_minus_signs).
        }
      }
    }

    a:not(.selected) {
      color: $tertiary;
      &:hover { color: $link-color; }
    }
  }
}
ul.accordion-tree {
  padding-left: 0;

  li {
    list-style: none;

    li {
      line-height: 1.2;
      margin-bottom: .35rem;
    }
  }
}
// END of "Two-level tree accordion"
// END of "Accordions"


.link-back {
  &.top {
    display: block;
    width: fit-content;
    margin-bottom: 1.5rem;
    margin-top: -1.5rem;
  }

  &:before {
    content: '←';
    font-family: 'Arial';
    font-size: 140%;
  }

  &:hover { text-decoration: none }
}


// The blue navigation bar that used on the homepage over the video.
// Not in use anymore, but the styles are kept for the future.

//#tertiary-navigation {
//  $padding-x: .5rem;
//  $border-width: .125rem;
//
//  display: none;
//  position: relative;
//  justify-content: center;
//  margin-top: -($font-size-lg * $line-height-base + $border-width * 2 + $padding-x * 2 + $hero-margin-bottom-desktop);
//  margin-bottom: $hero-margin-bottom-desktop;
//  @extend .mx-auto;
//
//  .nav-link {
//    font-size: $font-size-lg;
//    font-weight: $font-weight-light;
//    color: white;
//    border: $border-width solid theme-color('primary');
//    background-color: theme-color('primary');
//
//    &:not(:last-child) { margin-right: .0625rem; }
//    @include hover-focus() {
//      color: theme-color('primary');
//      background-color: white;
//    }
//  }
//
//  @include media-breakpoint-up('md') { display: flex; }
//}



//
// 3-COLUMN GRID DROPDOWN MENU
//
.menu-grid {
  --space-y: 1.5rem;
  --space-x: 1rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: $gray-200;
  gap: 1px;
  margin: var(--space-y) 1rem;
}

.menu-grid-category {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-inline: var(--space-x);
  gap: var(--space-x);
}

// First 3 cells
.menu-grid-category:nth-child(-n+3) {
  padding-bottom: var(--space-y);
}
// Last 3 cells
.menu-grid-category:nth-last-child(-n+3) {
  padding-top: var(--space-y);
}

.menu-grid-category-image {
  text-align: center;
  > img { max-height: 100px }
}

.menu-grid-category-title {
  font-weight: bold;
  margin-bottom: .25rem;
}
.menu-grid-category-description {
  max-width: 240px;
  font-size: .8em;
  line-height: 1.15;
  color: lighten($tertiary, 15%);
  margin-bottom: .5rem;
}

.menu-grid-category-products {
  // text-align: start ensures that the text is aligned properly on normal and Arabic languages.
  text-align: start;

  .dropdown-item {
    font-size: inherit !important;
    white-space: normal;
    padding-inline: 0;
    padding-block: $dropdown-item-padding-y !important;
  }
}

@include media-breakpoint-up(xl) {
  .menu-grid-category {
    flex-direction: row;
  }
  .menu-grid-category-image {
    flex: 0 0 30%;
    > img { max-height: 200px }
  }
}
