/** GOOGLE FONTS
    https://fonts.google.com/share?selection.family=Encode%20Sans%20Expanded:wght@200;300
    https://gwfh.mranftl.com/fonts/encode-sans-expanded?subsets=latin,latin-ext

    Encode Sans Expanded (latin):
      - ExtraLight (200)
      - Light (300)

    Roboto (latin ext):
      - Thin (100)
      - Light (300)
      - Regular (400)
      - Bold (700)
 */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/encode-sans-expanded-v11-latin_latin-ext-200.woff2') format('woff2');
}
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/encode-sans-expanded-v11-latin_latin-ext-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/roboto-v30-latin_latin-ext-300.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/roboto-v30-latin_latin-ext-regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/roboto-v30-latin_latin-ext-700.woff2') format('woff2');
}
