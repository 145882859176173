.form-control, .btn, .select2-selection {
  font-size: $font-size-lg;
  font-weight: 300;
}

// Removes highlighting of diadbled/readonly inputs.
.form-control:disabled:focus, .form-control[readonly]:focus {
  border-color: $input-border-color;
  box-shadow: none;
}

form {
  &.outline-transparent {
    .form-control {
      border-color: rgba(255, 255, 255, .5);
      background-color: rgba(255, 255, 255, .7);

      &:focus {
        z-index: auto; // Eliminates strange behavior with right border on focus.
        box-shadow: none;
        border-color: rgba(255, 255, 255, .8);
      }
    }
    .input-group {
      .btn {
        border-left: none;
        border-color: rgba(255, 255, 255, .2);
        background-color: rgba(255, 255, 255, .9);

        &:hover {
          background-color: white;
        }
      }
    }
  }

  &.condensed {
    .form-control {
      font-size: inherit;
    }
    .form-group {
      margin-bottom: $form-group-margin-bottom / 2;
    }
    [type="submit"] {
      margin-top: $form-group-margin-bottom;
    }
    .input-group [type="submit"] { margin-top: 0; }
  }

  .required:after {
    content: '*';
    color: $required-color;
    margin-left: 5px;
  }
}

.input-group {
  .btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .svg-icon {
    &.search { @include svg-icon-height('search', $font-size-base * $input-btn-line-height); }
  }
}


// Select2
// --------------------------------------------------------------
.select2-selection {
  @extend .custom-select;
  //height: calc(2.625rem + 2px) !important;
  height: $input-height !important;
  display: flex !important;
  align-items: center;
}
.select2-selection__rendered { padding: 0 !important; }
.select2-selection__arrow { display: none; }

.select2-selection, .select2-search__field {
  border-color: $custom-select-border-color !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: theme-color('primary') !important;
  //color: white;
}
.select2-search__field { @include form-control-focus(); }
// END of "Select2"


.form-success-submission {
  display: none;
  text-align: center;

  > :nth-child(2) { font-size: $font-size-lg; }
}

.svg-primary {
  fill: theme-color('primary');
  stroke: theme-color('primary');
}


#sidebar-search {
  margin-top: .0675rem;
}
#popup-search form {
  .svg-icon.search {
    @include svg-icon-height('search', 2rem * $input-btn-line-height);
  }
  .form-control {
    font-size: 2.8rem;
  }
}


// Add top margin to make popups to clear the header.
.modal-open .modal-dialog {
  margin-top: $header-height + $modal-dialog-margin-y-sm-up;
}
@include media-breakpoint-up('md') {
  .modal-open .modal-dialog {
    margin-top: $header-height + $modal-dialog-margin-y-sm-up + $sn-height;
  }
}
//.modal { z-index: $zindex-popover; }

.form-group {
  .form-check {
    padding-left: 0 !important;
    display: inline-block;
    width: 100%;
    label {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
    }
    .form-check-input {
      position: relative !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      margin-right: 10px;
    }
  }
}
