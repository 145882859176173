#mega-sidebar {
  position: fixed;
  overflow: auto;
  z-index: $nav-z-index;
  width: 0;
  min-width: 0;
  height: 100%;
  top: $header-height;
  right: 0;
  background-color: theme-color('primary');
  transition: min-width 0.15s ease;

  // Compensating bottom spacing.
  $language-selector-height: $font-size-lg * $line-height-base + $input-btn-padding-x;
  padding-bottom: $header-height + $language-selector-height;

  &.show {
    display: block;
    min-width: 100%;

    @include media-breakpoint-up(sm) {
      min-width: 400px;
    }
  }

  .phone-button {
    display: none;
    margin-top: .5rem;
    margin-left: map-get($spacers, 4);
  }
  .svg-icon.phone {
    @include svg-icon-width('phone', 1.5rem);
  }

  @include media-breakpoint-up('lg') {
    //top: 0;
    .phone-button { display: inline-block; }
  }
}


#mega-sidebar-menu {
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 3);
  padding: 0;
  list-style: none;

  ul { list-style: none }

  .item {
    margin-bottom: map-get($spacers, 2);

    &:last-child { margin-bottom: 0 }
    [data-toggle] {
      vertical-align: text-bottom;
      padding-left: $toggle-arrow-space;

      // -4rem fixes extra space on the right
      // because in order to have paddings we can't set "font-size: 0".
      padding-right: $toggle-arrow-space - .4rem;

      &.collapsed {
        > .toggle { transform: rotate(-90deg); }
      }
      &:hover {
        text-decoration: none;
        > .toggle { border-top-color: darken(white, 10%); }
      }

      > .toggle {
        @include arrow(bottom, .5rem, white);
        transition: transform 0.15s ease;
      }
    }

    // Main menu level
    > .section-title {
      // Ensures that even if a menu item spans over 2 lines it's still indented on the left
      display: inline-block;

      font-family: $headings-font-family;
      font-size: $h4-font-size;
      font-weight: $headings-font-weight;
      color: white;

      &:first-child { margin-left: $toggle-arrow-space * 2 + .9rem }
      &.active { text-decoration: underline }
    }

    // Submenu
    ul {
      padding-left: $toggle-arrow-space * 2 + .9rem; // .9rem is ~ width of the toggle arrow.
    }
    ul li {
      margin-top: map-get($spacers, 2);
      margin-bottom: .3rem;

      > a {
        color: $gray-700;
        &:hover { color: black; }
      }
    }
  }
}

#sidebar-language-selector {
  .dropdown-menu {
    background-color: transparentize(theme-color('light'), .05);

    // The language selector is styled with fixed font to look the same regardless of language.
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
  }

  .dropdown-toggle {
    text-transform: uppercase;
    font-weight: 400;
    color: theme-color('primary');
    border-color: transparentize(theme-color('primary'), .5);

    &.btn {
      padding-left: .6rem;
      padding-right: .4rem;
      background-color: rgba(255,255,255,0.9);
    }
  }
}
